import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { checkVisit } from '../functions/checkVisit';

export default function HomePage() {
    const navigate = useNavigate();

    useEffect(() => {
        const logos: any = document.querySelectorAll('.orbit-logo');

        const radius1 = 100; // Radius for the first logo
        const radius2 = 150; // Radius for the second logo
        const radius3 = 200; // Radius for the third logo

        let angle1 = 0;
        let angle2 = Math.PI; // 180 degrees in radians
        let angle3 = Math.PI * 1.5; // 270 degrees in radians
        let zoomFactor = 0;
        let zoomDirection = 0.2;

        function animate() {
            angle1 += 0.008; // Adjust the speed as needed
            angle2 += 0.01; // Adjust the speed as needed
            angle3 += 0.005; // Adjust the speed as needed

            // Update zoom factor
            if (zoomFactor >= 0.5) {
                zoomDirection = -2;
            } else if (zoomFactor <= 0) {
                zoomDirection = 0.3;
            }
            zoomFactor += 0.002 * zoomDirection; // Slow down the zoom speed

            logos[0].style.transform = `translate(${radius1 * Math.cos(angle1)}px, ${radius1 * Math.sin(angle1)}px) scale(${1 + zoomFactor})`;
            logos[1].style.transform = `translate(${radius2 * Math.cos(angle2)}px, ${radius2 * Math.sin(angle2)}px) scale(${1 + zoomFactor})`;
            logos[2].style.transform = `translate(${radius3 * Math.cos(angle3)}px, ${radius3 * Math.sin(angle3)}px) scale(${1 + zoomFactor})`;

            requestAnimationFrame(animate);
        }

        animate();

    }, []);

    useEffect(() => {
        const earth = document.querySelector('.earth') as HTMLImageElement;
        const mars = document.querySelector('.mars') as HTMLImageElement;
        const venus = document.querySelector('.venus') as HTMLImageElement;

        const handleEarthClick = () => {
            if (checkVisit("earth")) navigate("/earth")
            else alert("Hoop! Nereye böyle?");
        };
        const handleMarsClick = () => {
            let deneme = checkVisit("mars")
            debugger
            if (checkVisit("mars")) navigate("/mars")
            else alert("Hoop! Nereye böyle?");
        };
        const handleVenusClick = () => {
            if (checkVisit("venus")) navigate("/venus")
            else alert("Hoop! Nereye böyle?");
        };

        earth.addEventListener('click', handleEarthClick);
        mars.addEventListener('click', handleMarsClick);
        venus.addEventListener('click', handleVenusClick);

        // Clean up the event listeners on component unmount
        return () => {
            earth.removeEventListener('click', handleEarthClick);
            mars.removeEventListener('click', handleMarsClick);
            venus.removeEventListener('click', handleVenusClick);
        };
    }, [navigate]);

    useEffect(() => {

        let existVisit = localStorage.getItem("visits");
        if (existVisit) return;
        let visits: any = {
            home: true,
            mars: true,
            venus: false,
            earth: false
        }
        localStorage.setItem("visits", JSON.stringify(visits));

    }, []);

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="orbit">
                <h1 style={{ display: "flex", justifyContent: "center" }}>
                    <img width="300px" src="https://firebasestorage.googleapis.com/v0/b/uccisimproblemi.appspot.com/o/3cisim-white.png?alt=media&token=c4fd50f3-3272-4eb3-8135-87e5cfabf36f" alt="logo" />
                </h1>

                <img src="https://upload.wikimedia.org/wikipedia/commons/7/7f/Rotating_earth_animated_transparent.gif" className="App-logo orbit-logo earth" alt="earth" />
                <img src="https://media.tenor.com/SqJHatCJ40cAAAAj/mars.gif" className="App-logo orbit-logo mars" alt="mars" />
                <img src="https://media.baamboozle.com/uploads/images/253430/1682775182_998904_gif-url.gif" className="App-logo orbit-logo venus" alt="venus" />
            </div>
        </div>
    );
}
