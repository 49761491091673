import React, { useEffect } from 'react'
import { getPage } from '../functions/getPage'
import { setVisit } from '../functions/setVisit'

export default function EarthPage() {
    const [data, setData] = React.useState<any>(null)
    const [answer, setAnswer] = React.useState<any>(null)

    useEffect(() => {
        const pageData = async () => {
            let response = await getPage("earth")
            setData(response)

        }
        pageData()
    }, [])
    return (
        <div>
            <h1><img width="300px" src="https://firebasestorage.googleapis.com/v0/b/uccisimproblemi.appspot.com/o/3cisim-blue.png?alt=media&token=8cac0782-15b7-4c25-a1b5-d55f0ae52451" alt="logo" /></h1>
            <div className='find' >
                <div className='header'>
                    <span>{`${data?.episode}:${data?.place}`} </span> <br /> <hr />

                    <span>Bul </span>
                </div>
                <div className='content'>
                    <img width="200px" src={data?.find} alt="cake" />

                </div>
            </div>

            <div className='answers' >
                <div className='header'>
                    Cevap
                </div>
                <div className='content'>
                    <input className='answerInput' type="text" placeholder='cevap' onChange={(e: any) => setAnswer(e.target.value)} />
                </div>
                <div className='btn'>
                    <input className='answerButton' type="button" value="Tamam" onClick={() => {
                        if (data?.answer === String(answer).toLocaleLowerCase()) {
                            let result = window.confirm(data?.successMessage)
                            if (result) {
                                window.open(data?.successMessage, '_blank', 'rel=noopener noreferrer')
                                setVisit("earth", "mars")
                                window.location.href = '/'
                            }
                        }
                        else {
                            alert(data?.errorMessage)
                        }
                    }} />
                </div>
                <div className='btn'>
                    <input className='hintButton' type="button" value="İpucu" onClick={() => {
                        data?.hint?.includes("http") ? window.open(data?.hint, '_blank', 'rel=noopener noreferrer') : alert(data?.hint)
                    }} />
                </div>
            </div>
        </div>
    )
}
