import React from 'react';
import { Routes, Route } from 'react-router-dom';
import EarthPage from './pages/EarthPage';
import VenusPage from './pages/VenusPage';
import MarsPage from './pages/MarsPage';
import HomePage from './pages/HomePage';



export default function Router() {
    return (
        <Routes>
            <Route path='/' Component={HomePage} />
            <Route path='/earth' Component={EarthPage} />
            <Route path='/mars' Component={MarsPage} />
            <Route path='/venus' Component={VenusPage} />
        </Routes>
    );
}