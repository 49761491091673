import React, { useEffect } from 'react'
import { setVisit } from '../functions/setVisit'
import { getPage } from '../functions/getPage'

export default function MarsPage() {
    const [data, setData] = React.useState<any>(null)
    const [answer, setAnswer] = React.useState<any>(null)

    useEffect(() => {
        const pageData = async () => {
            let response = await getPage("mars")
            setData(response)

        }
        pageData()
    }, [])
    return (
        <div>
            <h1><img width="300px" src="https://firebasestorage.googleapis.com/v0/b/uccisimproblemi.appspot.com/o/3cisim-red.png?alt=media&token=31f5151e-e16b-4e34-93b1-d1c5531f9a47" alt="logo" /></h1>
            <div className='find' >
                <div className='header'>
                    <span>{`${data?.episode}:${data?.place}`} </span> <br /> <hr />

                    <span>Bul </span>
                </div>
                <div className='content'>
                    <span>{data?.find}</span>

                </div>
            </div>
            <div className='clue' >
                <div className='header'>
                    <span>Şifre </span>
                </div>
                <div className='content'>
                    <span>{data?.clue}</span>

                </div>
            </div>
            <div className='answers' >
                <div className='header'>
                    Cevap
                </div>
                <div className='content'>
                    <input className='answerInput' type="text" placeholder='cevap' onChange={(e: any) => setAnswer(e.target.value)} />
                </div>
                <div className='btn'>
                    <input className='answerButton' type="button" value="Tamam" onClick={() => {
                        if (data?.answer === String(answer).toLocaleLowerCase()) {
                            let result = window.confirm(data?.successMessage)
                            if (result) {
                                setVisit("mars", "venus")
                                window.location.href = '/'
                            }
                        }
                        else {
                            alert(data?.errorMessage)
                        }
                    }} />
                </div>
                <div className='btn'>
                    <input className='hintButton' type="button" value="İpucu" onClick={() => {
                        data?.hint?.includes("http") ? window.open(data?.hint, '_blank', 'rel=noopener noreferrer') : alert(data?.hint)
                    }} />
                </div>
            </div>


        </div >
    )
}
